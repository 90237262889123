import styled from '@emotion/styled';

const TextContainer = styled('div')({
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '0',
  paddingLeft: '1.5rem',
  paddingRight: '0.75rem',
  paddingTop: '0',
  width: 'calc(100% - 6rem)',
});

export default TextContainer;
