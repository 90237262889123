import DescriptionWrapper from 'primitives/Hero/DescriptionWrapper';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroOverlay from 'components/Hero/HeroOverlay/HeroOverlay';
import HeroPlayButton from 'components/Player/HeroPlayButton';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroRoot from 'primitives/Hero/HeroRoot';
import HeroTitle from 'primitives/Hero/HeroTitle';
import LiveDescription from 'components/LiveDescription';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import { blur, gravity } from 'utils/mediaServerImageMaker/opsString';
import {
  PrimaryBackground,
  TabletBackground,
  Thumbnail,
} from 'components/Hero/helpers';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';
import type { LiveStation } from 'state/Live/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  backgroundColor?: string;
  mediaServerUrl: string;
  primaryBackgroundSrc?: string;
  seedId: string;
  siteUrl: string;
  station: LiveStation;
  stationName: string;
  subscriptionType: string;
  resetHero: () => void;
  setHasHero: () => void;
};

class GenreHero extends PureComponent<Props> {
  componentWillUnmount() {
    const { resetHero, setHasHero } = this.props;

    resetHero();
    setHasHero();
  }

  render() {
    const {
      backgroundColor,
      mediaServerUrl,
      primaryBackgroundSrc,
      seedId,
      siteUrl,
      station,
      stationName,
      subscriptionType,
    } = this.props;

    return (
      <HeroRoot data-test="hero-container">
        <HeroBackground
          backgroundColor={backgroundColor}
          noMask
          primaryBackground={
            primaryBackgroundSrc ?
              <PrimaryBackground
                mediaServerUrl={mediaServerUrl}
                ops={[gravity('center'), blur(40)]}
                siteUrl={siteUrl}
                src={primaryBackgroundSrc}
                title={stationName}
              />
            : null
          }
          tabletBackground={
            <TabletBackground
              customBlur={40}
              mediaServerUrl={mediaServerUrl}
              seedId={seedId}
              seedType={STATION_TYPE.LIVE}
              title={stationName}
            />
          }
        />
        <HeroOverlay>
          <HeroMidSection>
            <ThumbnailWrapper>
              <Thumbnail
                mediaServerUrl={mediaServerUrl}
                seedId={seedId}
                seedType={STATION_TYPE.LIVE}
                title={stationName}
              />
            </ThumbnailWrapper>
            <HeroContent>
              <HeroTitle>
                <Title clamp text={station.name} />
              </HeroTitle>
              <HeroPlaySection>
                {seedId ?
                  <PlayButton
                    ButtonComponent={HeroPlayButton}
                    playedFrom={PLAYED_FROM.PROF_GENRE_PLAY}
                    seedId={seedId}
                    stationId={seedId}
                    stationType={STATION_TYPE.LIVE}
                    subscriptionType={subscriptionType}
                  />
                : null}
                <DescriptionWrapper>
                  <LiveDescription text={station.description} />
                </DescriptionWrapper>
              </HeroPlaySection>
            </HeroContent>
          </HeroMidSection>
        </HeroOverlay>
      </HeroRoot>
    );
  }
}

export default GenreHero;
