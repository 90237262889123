import styled from '@emotion/styled';

const Headline = styled('h4')(({ theme }) => ({
  ...theme.mixins.ellipsis,
  fontSize: '1.6rem',
  fontWeight: 'normal',
  lineHeight: '2.16rem',
  margin: 0,
}));

export default Headline;
