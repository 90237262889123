import TopStations, { Props } from './TopStations';
import { Artist } from 'state/Artists/types';
import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAmpUrl } from 'state/Config/selectors';
import { getCurrentMarket } from 'state/Location/selectors';
import { getFavoriteStations } from 'state/Stations/selectors';
import { LiveStation } from 'state/Live/types';
import { Market } from 'state/Location/types';
import { State } from 'state/types';

type ConnectedProps = {
  ampUrl: string;
  currentMarket: Props['currentMarket'];
  favoriteStations: Array<Artist | LiveStation>;
};

type TopStationsType = ComponentType<
  Pick<Props, Exclude<keyof Props, keyof ConnectedProps>>
>;

export default connect(
  createStructuredSelector<
    State,
    {
      ampUrl: string;
      currentMarket: Market;
      favoriteStations: Array<LiveStation>;
    }
  >({
    ampUrl: getAmpUrl,
    currentMarket: getCurrentMarket,
    favoriteStations: getFavoriteStations,
  }),
)(TopStations) as TopStationsType;
