import ContentDetail from 'components/ContentDetail';
import Section from 'components/Section';
import { mapGenres } from '../helpers';
import { useTranslate } from 'widget/hooks';
import type { Genre } from 'state/Genres/types';

type Props = {
  genres: Record<string, Genre>;
  mediaServerUrl: string;
  seedId: string;
  siteUrl: string;
};

function Recommendations({ genres, mediaServerUrl, seedId, siteUrl }: Props) {
  const translate = useTranslate();
  const otherGenres = mapGenres(
    { mediaServerUrl, siteUrl },
    Object.values(genres)
      .filter(genre => genre.sparkStreamId !== seedId)
      .sort((a, b) => a.sort - b.sort)
      .slice(0, 3),
  );

  const moreGenres = (
    <Section
      dataTest="more-genres"
      header={translate('More Genres')}
      isHidden={!otherGenres.length}
      url="/genre/"
    >
      {otherGenres.map(({ imgSrc, name, url: target, id }) => (
        <ContentDetail imgSrc={imgSrc} key={id} target={target} title={name} />
      ))}
    </Section>
  );

  return moreGenres;
}

export default Recommendations;
