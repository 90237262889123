import GenericHead from 'components/GenericHead';
import { Helmet } from 'react-helmet';

export type Props = {
  deeplink: string;
  description: string;
  keywords: string;
  logo: string;
  noAltLink?: boolean;
  title: string;
};

export default function Head({
  deeplink,
  description,
  keywords,
  logo,
  noAltLink,
  title,
}: Props) {
  return (
    <>
      <GenericHead
        deeplink={deeplink}
        image={logo}
        metaDescription={description}
        noAltLink={noAltLink}
        ogType="music.radio_station"
        title={title}
      />
      <Helmet meta={[{ content: keywords, name: 'keywords' }]} />
    </>
  );
}
