import styled from '@emotion/styled';

const Wrapper = styled('div')({
  display: 'flex',

  ':not(:last-of-type)': {
    paddingBottom: '1rem',
  },
});

export default Wrapper;
