import GenreHero from './Hero';
import {
  backgroundColor,
  noLogo,
  primaryBackgroundSrc,
} from 'state/Hero/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentGenreName,
  getCurrentGenreSparkStreamId,
} from 'state/Genres/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import { getStationFromGenre } from 'state/Live/selectors';
import { getSubscriptionType } from 'state/Entitlements/selectors';
import {
  resetHero,
  setHasHero,
  setHeroPremiumBackground,
} from 'state/Hero/actions';

export default connect(
  createStructuredSelector({
    backgroundColor,
    mediaServerUrl: getMediaServerUrl,
    noLogo,
    primaryBackgroundSrc,
    seedId: getCurrentGenreSparkStreamId,
    siteUrl: getSiteUrl,
    station: getStationFromGenre,
    stationName: getCurrentGenreName,
    subscriptionType: getSubscriptionType,
  }),
  {
    resetHero,
    setHasHero,
    setHeroPremiumBackground,
  },
)(GenreHero);
