import Image from 'components/Image';
import styled from '@emotion/styled';

const DetailImage = styled(Image)({
  display: 'inline-block',
  height: '6rem',
  marginRight: 0,
  width: '6rem',
});

export default DetailImage;
