import checkAutoplay from 'web-player/autoplay';
import GenreHero from './Hero';
import Head from './Head';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import Recommendations from './Recommendations';
import Section from 'components/Section';
import ShowMoreTiles from 'components/ShowMoreTiles';
import TopStations from 'components/TopStations';
import { Article } from 'state/News/types';
import {
  createNewsTile,
  NEWS_ASPECT_RATIO,
} from 'components/LatestNews/NewsChildren';
import { getGenreUrl } from './helpers';
import { gravity, ratio } from 'utils/mediaServerImageMaker/opsString';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';
import { translateKeywords } from 'utils/metaUtils';
import type { Genre } from 'state/Genres/types';

export type Props = {
  articles: Array<Article>;
  contentLink: string;
  countryCode: string;
  genreId: number;
  genreName: string;
  genres: Record<string, Genre>;
  logo: string;
  mediaServerUrl: string;
  seedId: string;
  siteUrl: string;
  stationLoaded?: boolean;
  translate: IGetTranslateFunctionResponse;
};

const NEWS_TILES_IN_ROW = 2;

export default class GenrePage extends PureComponent<Props> {
  componentDidMount() {
    const { seedId, stationLoaded } = this.props;

    if (stationLoaded) {
      checkAutoplay({ seedId, seedType: STATION_TYPE.LIVE });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ seedId, stationLoaded }: Props) {
    if (stationLoaded !== this.props.stationLoaded) {
      checkAutoplay({ seedId, seedType: STATION_TYPE.LIVE });
    }
  }

  render() {
    const {
      contentLink,
      genres,
      genreId,
      genreName,
      seedId,
      logo,
      translate,
      siteUrl,
      mediaServerUrl,
      articles,
    } = this.props;
    const url = getGenreUrl(genreId, genreName);
    const description = translate(
      'Find and stream {genreName} music stations for free, only on iHeart.',
      { genreName },
    );

    const recommendations = (
      <Recommendations
        genres={genres}
        mediaServerUrl={mediaServerUrl}
        seedId={seedId}
        siteUrl={siteUrl}
      />
    );

    const social = {
      description,
      logo,
      seedId,
      seedType: 'genre',
      stationName: `${genreName} Radio`,
      supportsConnect: false,
      url: url as string,
    };

    return (
      <>
        <Head
          deeplink={`/goto/genre/${genreId}`}
          description={description}
          keywords={translateKeywords(
            translate,
            'Music, Radio, Stations, Playlists, Stream, Free, On Demand, iHeartRadio, iHeart',
          )}
          title={translate('Listen to {genreName} Radio Stations for Free', {
            genreName,
          })}
        />
        <GenreHero />
        <ListenInApp seedId={seedId} seedType={STATION_TYPE.LIVE} />
        <PageBody
          dataTest={ViewName.GenrePage}
          hasHero
          more={recommendations}
          social={social}
          title={translate('Find {genreName} Stations', { genreName })}
        >
          <TopStations
            genreId={genreId}
            header={translate('Featured {genreName} Stations', { genreName })}
            key={`DL|${genreId}`}
            playedFrom={PLAYED_FROM.PROF_GENRE_MAIN_FEATURED_STATIONS}
            stationId={Number(seedId)}
            template="DL"
          />
          <TopStations
            genreId={genreId}
            header={translate('Top {genreName} Stations', { genreName })}
            key={`LRRM|${genreId}`}
            playedFrom={PLAYED_FROM.PROF_GENRE_MAIN_TOP_STATIONS}
            stationId={Number(seedId)}
            template="LRRM"
          />
          <TopStations
            genreId={genreId}
            header={translate('Top {genreName} Artists', { genreName })}
            key={`CR|${genreId}`}
            playedFrom={PLAYED_FROM.PROF_GENRE_MAIN_TOP_ARTISTS}
            stationId={Number(seedId)}
            template="CR"
          />
          <Section dataTest="section-news" header={`${genreName} News`}>
            <ShowMoreTiles
              aspectRatio={NEWS_ASPECT_RATIO}
              tilesData={articles.map(article =>
                createNewsTile({
                  title: article.title,
                  href: `${contentLink}${article.slug}/`,
                  key: article.resource_id,
                  isSponsored: article.is_sponsored,
                  img: article['@img'],
                  ops: [gravity('center'), ratio(NEWS_ASPECT_RATIO, 1)],
                  translate,
                  tilesInRow: NEWS_TILES_IN_ROW,
                }),
              )}
              tilesInRow={NEWS_TILES_IN_ROW}
              titleLines={4}
            />
          </Section>
        </PageBody>
      </>
    );
  }
}
