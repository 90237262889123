import Headline from './primitives/Headline';
import Image from './primitives/Image';
import NavLink from 'components/NavLink';
import SubTitle from './primitives/SubTitle';
import TextContainer from './primitives/TextContainer';
import Wrapper from './primitives/Wrapper';
import { ReactElement } from 'react';

type Props = {
  imgSrc?: string;
  isExternal?: boolean;
  clickedFrom?: string;
  subTitle?: string;
  target: string | null;
  title: string;
};

function ContentDetail({
  imgSrc = '',
  isExternal = false,
  clickedFrom,
  subTitle,
  target,
  title,
}: Props): ReactElement<Props> {
  const linkTarget = isExternal ? '_blank' : undefined;

  return (
    <Wrapper data-test="content-detail-wrapper">
      <NavLink
        clickedFrom={clickedFrom}
        supressDeepLink
        target={linkTarget}
        to={target}
      >
        <Image alt={title} aspectRatio={1} src={imgSrc!} />
      </NavLink>
      <TextContainer data-test="content-detail-text-container">
        <Headline>
          <NavLink
            clickedFrom={clickedFrom}
            supressDeepLink
            target={linkTarget}
            to={target}
          >
            {title}
          </NavLink>
        </Headline>
        <SubTitle>{subTitle}</SubTitle>
      </TextContainer>
    </Wrapper>
  );
}

export default ContentDetail;
