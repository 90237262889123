import Head, { Props } from './Head';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCurrentGenreLogo } from 'state/Genres/selectors';
import type { State } from 'state/types';

type ConnectedProps = Pick<Props, 'logo'>;

export default connect(
  createStructuredSelector<State, ConnectedProps>({
    logo: getCurrentGenreLogo,
  }),
)(Head);
